import React from 'react';
import { ExploreOurOfferSection } from 'sections/Home/ExploreOurOfferSection';
import { Hero } from 'sections/Home/Hero/Hero';
import { HowWeWorkSection } from 'sections/Home/HowWeWorkSection';
import { OurImpactSection } from 'sections/Home/OurImpactSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { QuoteSection } from 'sections/Home/QuoteSection';
import { TestimonialsSection } from 'sections/Home/TestimonialsSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import SEO from 'components/_layout/Seo';

export default () => {
    return (
        <>
            <SEO
                title="Home"
                meta={[
                    {
                        name: 'keywords',
                        content:
                            'embedded ai, react native app development services, react native debugger, latest react version, react-app-rewired, react and react native, react software, angular software, react native reanimated, react native gesture handler, react native ide, react native slider, react native bottom sheet, react-native-web, react animation, react-spring, react-native-screens, react switch, react accordion, react debugger, react modal, react-native-modal, react setstate callback, expo development build, react native for web, react-native-config, react native share, react native flatlist, react native image, jest react, jest npm, Assisting global businesses in their deep tech transformation, Comprehensive software development and consulting services, Bridging the gap between hardware and software for modern businesses, Transforming client tech infrastructure for Web 3.0, Leveraging AI and machine learning for innovative solutions, Prototyping and developing advanced hardware solutions, Advanced data analytics for web and mobile applications, Streamlining payment integration for global businesses, Creating impactful tech solutions for startups and corporations, Enhancing user experience with tailored UX/UI design, Emerging technologies in business transformation, Importance of outsourcing in tech development, Role of AI in shaping future industries, Designing user-centric mobile and web applications, Clean energy technology in modern business practices, Innovations in communication protocols for IoT, Trends in augmented and virtual reality solutions, Global impact of technology solutions on industries, Building functional prototypes for market validation, Addressing the challenges of the modern tech landscape, ',
                    },
                ]}
                description={
                    'Unlock the potential of embedded AI with our expert React Native app development services. Leverage the latest React version and tools like React Native debugger, react-app-rewired, and React Native IDE to enhance your projects. Our solutions include React Native reanimated, gesture handler, and custom components like react-native-slider and bottom sheet. From react-native-web to react animation with react-spring, we assist global businesses in their deep tech transformation and streamline payment integration. Our comprehensive software development and consulting services bridge the gap between hardware and software, transforming your tech infrastructure for Web 3.0 and driving innovation in clean energy technology.'
                }
            />
            <Hero />
            <QuoteSection />
            <TrustedBySection />
            <ExploreOurOfferSection />
            <OurImpactSection />
            <HowWeWorkSection />
            <TestimonialsSection />
            <TakeThisStepSection />
        </>
    );
};
