import bgFishial from 'assets/images/_backgrounds/backgroundScubaDriver.png';

import bgRespectEnergy from 'assets/images/_backgrounds/backgroundShutterstock.png';
import bgTulerie from 'assets/images/_backgrounds/bgTulerie.png';
import bgUdacity from 'assets/images/_backgrounds/bgUdacity.png';
import LogoUdacity from 'assets/images/_logos/companies/logoUdacitySmaller.png';
import LogoRespectEnergy from 'assets/images/_logos/companies/RESPECT_ENERGY_SMALLER.png';
import LogoSpotOn from 'assets/images/_logos/companies/SPOTON.png';
import LogoTelebiz from 'assets/images/_logos/companies/TELEBIZ.svg';
import LogoFishial from 'assets/images/_logos/logoFishial.png';
import LogoGranturismo from 'assets/images/_logos/logoGranturismo.png';
import LogoListables from 'assets/images/_logos/logoListables.png';
import LogoMusicRadio from 'assets/images/_logos/logoMusicRadio.png';
import LogoPistGliwice from 'assets/images/_logos/logoPistGliwice.png';
import LogoScoreSplit from 'assets/images/_logos/logoScoreSplit.png';
import LogoTickPredict from 'assets/images/_logos/logoTickPredict.png';
import LogoTulerie from 'assets/images/_logos/logoTulerie.png';
import * as Common from 'components/_universal/Common';
import { ExploreButton } from 'components/_universal/ExploreButton';
import { navigate } from 'gatsby';
import React from 'react';
import { ExploreContainer } from 'sections/CaseStudies/CaseStudyItem';
import {
    Description,
    ImageBackground,
    SwiperDotsFiller,
    TestimonialContentWrapper,
    TestimonialItemWrapper,
} from 'sections/Home/TestimonialsSection.styled';
import {
    ROUTE_CASE_STUDIES_FISHIAL,
    ROUTE_CASE_STUDIES_NUUS,
    ROUTE_CASE_STUDIES_RESPECT,
    ROUTE_CASE_STUDIES_SPOTON,
    ROUTE_CASE_STUDIES_TICK_PREDICT,
    ROUTE_CASE_STUDIES_TULERIE,
    ROUTE_CASE_STUDIES_UDACITY,
} from 'shared/paths';
import styled, { css } from 'styled-components';
import { B3, B4 } from 'styles/Typography.styled';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const StyledExploreContainer = styled(ExploreContainer)(
    () =>
        css`
            width: calc(100% - 32px);
        `,
);

export const EmptyExplore = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            width: 100%;
            background: ${colors.background};
            height: 58px;
        `,
);

const TEXT_GRANTURISMO = `As a company working with\n premium brands and customers\n with extremely high demands, it is\n imperative that we work with\n partners that share our drive for\n high quality of service. We are\n happy to recommend Codahead.`;
const TEXT_FISHIAL = `The largest open-source library of fish\n species images for AI-powered\n machine learning and striving to\n develop a highly accurate open-source\n AI model that can identify fish species\n worldwide.`;
const TEXT_SPOTON = `Their work on our products has\n made a significant impact on our\n business. They are team players\n and have helped us by building\n amazing products from concept to\n deployment.`;
const TEXT_TICK_PREDICT = `Codahead’s team is an integral part\n of our development team. We are\n able to work seamlessly back and\n forth, with zero language or cultural\n issues. The work product is top\n notch in both quality and speed.\n They are uor secret weapon, so\n don’t tell anyone.`;
const TEXT_RESPECT_ENERGY = `It is one of the largest independent\n entities on the electricity market in\n Poland. They manage electricity that\n comes only from renewable energy\n sources, offering our clients only 100%\n green energy.`;
const TEXT_MUSIC_RADIO = `It’s great to have a team of experts\n on the other and of the email.\n Professional, fast and easy to\n communicate with. Our website\n development has never been\n easier!`;
const TEXT_LISTABLES = `I have had the pleasure of working\n with Codahead for the past couple\n of years. I have always enjoyed\n working with them. The team is\n diligent and through in their\n deliverables and are an absolute\n fun set of people to work with.`;
const TEXT_TULERIE = `Tulerie is a peer to peer clothing rental\n community, allowing women to earn\n money on the investments they have\n made in their closets.`;
const TEXT_GLIWICE = `Piast Gliwice for Android and iOS\n give new ways of connecting with\n our supporters. With push\n notifications we can inform them of\n the most important news from the\n club. Nuus engine - the heart of the\n app - is still developing. In a few\n months we will introduce ticket and\n loyalty program modules. `;
const TEXT_SCORE_SPLIT = `They have been unbelieveable to\n work with. From the beginning,they\n not only have produced an\n excellent product, but they have\n helped me understand the entire\n process. Their organization and\n willingness to do whatever I want\n and need (I have woken them up in\n the middle of the night) is second\n to none.`;
const TEXT_UDACITY = `The latest tech knowledge. Expert\n instructors. Personalized feedback.\n Join our community of 17 million\n learners gaining project-based skills.`;
const TEXT_TELEBIZ = `Working with Team Codahead has\n been an elating experience. The\n unique blend of formality and\n flexybility displayed by Codahead\n was exactly what Telebiz was\n demending from a successful\n partner. We recommend Codahead\n team for their cost conscious\n professional approach and very\n agile technical abilities.`;

export const TestimonialSlides = ({
    setSwiperRef,
}: {
    setSwiperRef: (swiper: SwiperClass) => void;
}) => {
    const isXs = useBreakpoint('xs');

    // NOTE: Swiper breakpoints must be synchronized with breakpoints in styled
    return (
        <Swiper
            onSwiper={setSwiperRef}
            autoplay={{
                delay: 7500,
                disableOnInteraction: false,
            }}
            spaceBetween={32}
            className={'swiper'}
            loop
            modules={[Pagination, Navigation, Autoplay]}
            pagination={isXs && { clickable: true }}
            breakpoints={{
                1400: {
                    slidesPerView: 3,
                },
                1080: {
                    slidesPerView: 2,
                },
                1079: {
                    slidesPerView: 1,
                },
            }}
        >
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoGranturismo}
                                mt={6}
                                mb={8}
                                w="99px"
                            />
                            <Description mb={4} italic>
                                {TEXT_GRANTURISMO}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Peter Ternstöm</B3>
                                <B4>Owner, Gran Turismo Events </B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <EmptyExplore />
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <ImageBackground src={bgFishial} />
                        <TestimonialContentWrapper pl={2} pr={2}>
                            <Common.Svg
                                src={LogoFishial}
                                mt={4}
                                w="157px"
                                mb={2}
                            />
                            <Description>{TEXT_FISHIAL}</Description>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_FISHIAL)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper p={4}>
                            <Common.Div
                                justifyContent="center"
                                flex="row"
                                mt={6}
                            >
                                <Common.Svg src={LogoSpotOn} w="144px" />
                            </Common.Div>
                            <Description mt={8} mb={2} italic>
                                {TEXT_SPOTON}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Max Roper</B3>
                                <B4>CEO/CTO, Spoton</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_SPOTON)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoTickPredict}
                                mt={8}
                                mb={8}
                                w="160px"
                            />
                            <Description mb={4} italic>
                                {TEXT_TICK_PREDICT}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Randy Myers</B3>
                                <B4>CEO, TickPredict</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() =>
                                navigate(ROUTE_CASE_STUDIES_TICK_PREDICT)
                            }
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <ImageBackground src={bgRespectEnergy} />
                        <TestimonialContentWrapper pl={2} pr={2}>
                            <Common.Div flex="column" alignItems="center">
                                <Common.Svg
                                    src={LogoRespectEnergy}
                                    mt={2}
                                    w="187px"
                                    mb={2}
                                />
                                <Description>{TEXT_RESPECT_ENERGY}</Description>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_RESPECT)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoMusicRadio}
                                mt={8}
                                mb={8}
                                w="160px"
                            />
                            <Description mb={4} italic>
                                {TEXT_MUSIC_RADIO}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Izabella Russel</B3>
                                <B4>Managing Director, Music Radio Create</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <EmptyExplore />
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoListables}
                                mt={10}
                                mb={6}
                                w="177px"
                            />
                            <Description mb={5} italic>
                                {TEXT_LISTABLES}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Vivek Chugh</B3>
                                <B4>CEO, Listables</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <EmptyExplore />
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <ImageBackground src={bgTulerie} />
                        <TestimonialContentWrapper pl={2} pr={2}>
                            <Common.Svg
                                src={LogoTulerie}
                                mt={4}
                                w="60px"
                                mb={4}
                            />
                            <Description>{TEXT_TULERIE}</Description>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_TULERIE)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoPistGliwice}
                                mt={6}
                                mb={6}
                                w="69px"
                            />
                            <Description mb={3} italic>
                                {TEXT_GLIWICE}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Michał Draczyński</B3>
                                <B4> PR Manager, Piast Gliwice SA</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_NUUS)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoScoreSplit}
                                mt={6}
                                mb={6}
                                w="94px"
                            />
                            <Description mb={5} italic>
                                {TEXT_SCORE_SPLIT}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Matt Synowiecki</B3>
                                <B4> CEO, SquareStripSports</B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <EmptyExplore />
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <ImageBackground src={bgUdacity} />
                        <TestimonialContentWrapper pl={2} pr={2}>
                            <Common.Div flex="column" alignItems="center">
                                <Common.Svg
                                    src={LogoUdacity}
                                    mt={6}
                                    w="191px"
                                    mb={4}
                                />
                                <Description>{TEXT_UDACITY}</Description>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <StyledExploreContainer>
                        <ExploreButton
                            onClick={() => navigate(ROUTE_CASE_STUDIES_UDACITY)}
                        />
                    </StyledExploreContainer>
                </Common.Div>
            </SwiperSlide>
            <SwiperSlide>
                <Common.Div flex="column">
                    <TestimonialItemWrapper>
                        <TestimonialContentWrapper pl={4} pr={4}>
                            <Common.Svg
                                src={LogoTelebiz}
                                mt={6}
                                mb={6}
                                w="124px"
                            />
                            <Description mb={5} italic>
                                {TEXT_TELEBIZ}
                            </Description>
                            <Common.Div
                                flex="column"
                                alignItems="flex-end"
                                w="100%"
                            >
                                <B3 bold={true}>Tariq Mustafa</B3>
                                <B4>
                                    {' '}
                                    VP of Technology, Multinet - Telebiz Int.
                                </B4>
                            </Common.Div>
                        </TestimonialContentWrapper>
                    </TestimonialItemWrapper>
                    <EmptyExplore />
                </Common.Div>
            </SwiperSlide>
            {isXs && <SwiperDotsFiller />}
        </Swiper>
    );
};
