import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { B2 } from 'styles/Typography.styled';

export const ExploreButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Common.Div
            flex="row"
            alignItems="center"
            justifyContent="flex-end"
            onClick={onClick}
        >
            <B2 align="right" mr={1}>
                explore
            </B2>
            <img src={ArrowSvg} alt="exploreButton" />
        </Common.Div>
    );
};
