import heroAnimation from 'assets/animations/heroAnimation.webm';
import TECHSVG from 'assets/images/_infoGraphics/TECHcaption.svg';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    HeroAnimationVideo,
    HeroDescription,
    HeroSVGTitle,
    HeroTitle,
    HeroWrapper,
} from './Hero.styled';

export const Hero = () => {
    const isMd = useBreakpoint('md');

    return (
        <>
            {!isMd && (
                <HeroAnimationVideo autoPlay loop muted preload="auto">
                    <source src={heroAnimation} type="video/webm" />
                </HeroAnimationVideo>
            )}
            <HeroWrapper isMobile={isMd}>
                <div>
                    <Common.Div
                        mt={2}
                        flex={isMd ? 'column' : 'row'}
                        gap={isMd ? '0' : '48px'}
                        justifyContent="space-between"
                    >
                        <HeroDescription>
                            The Deep <br />
                            Transformation <br /> For the Global
                        </HeroDescription>
                        <div>
                            {isMd ? (
                                <Common.Div flex="row" justifyContent="center">
                                    <HeroSVGTitle src={TECHSVG} />
                                </Common.Div>
                            ) : (
                                <HeroTitle>TECH</HeroTitle>
                            )}
                        </div>
                    </Common.Div>
                </div>
            </HeroWrapper>
        </>
    );
};
