import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import shortid from 'shortid';
import styled, { css } from 'styled-components';
import { B0, B2 } from 'styles/Typography.styled';

export interface ICaseStudyItemProps {
    bgSrc: string;
    desc: string;
    link: string;
    title: string;
}

export const CaseStudiesContainer = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            flex-direction: column;
            max-width: 350px;
            height: 440px;
            gap: 16px;
            background: ${colors.background};
        `,
);

export const LogoCompanyBackground = styled(Common.Svg)(
    () =>
        css`
            width: 100%;
            height: 215px;

            ${mediaQueries.xs} {
                height: auto;
            }
        `,
);

export const CaseStudyDescription = styled(Common.Div)(
    () =>
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px 32px 32px 32px;
        `,
);

export const CaseStudyTitle = styled(B0)(
    () =>
        css`
            ${mediaQueries.xs} {
                font-size: 21px;
            }
        `,
);

export const CaseStudyShortDesc = styled(B2)(
    () =>
        css`
            ${mediaQueries.xs} {
                font-size: 12px;
            }
        `,
);

export const ExploreContainer = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            background: ${colors.background};
            padding-right: 32px;
            padding-bottom: 32px;
        `,
);

export const CaseStudyItem = ({
    bgSrc,
    desc,
    link,
    title,
}: ICaseStudyItemProps) => {
    return (
        <div key={shortid.generate()}>
            <CaseStudiesContainer>
                <LogoCompanyBackground src={bgSrc} />
                <CaseStudyDescription>
                    <Common.Div>
                        <CaseStudyTitle mb={3}>{title}</CaseStudyTitle>
                        <CaseStudyShortDesc>{desc}</CaseStudyShortDesc>
                    </Common.Div>
                </CaseStudyDescription>
            </CaseStudiesContainer>
            <ExploreContainer onClick={() => navigate(link)}>
                <B2 align="right" mr={1}>
                    explore
                </B2>
                <img src={ArrowSvg} alt="exploreButton" />
            </ExploreContainer>
        </div>
    );
};
