import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { ExploreButton } from 'components/_universal/ExploreButton';
import { navigate } from 'gatsby';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import { ROUTE_OFFER } from 'shared/paths';
import styled, { css } from 'styled-components';
import { H3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { drawTextWithLineBreaks } from 'utils/strings';
import iconSoftware from 'assets/images/_icons/iconSoftware.svg';
import iconHardware from 'assets/images/_icons/iconHardware.svg';
import iconDeepTech from 'assets/images/_icons/iconDeepTech.svg';

export const OfferText = styled(Common.Div)(
    ({ theme: { fontSizes } }) =>
        css`
            font-size: ${fontSizes.b3};
            line-height: 25px;
        `,
);

export const VerticalLine = styled(Common.Div)(
    () =>
        css`
            border-right: 1px solid white;
            height: 100%;
            max-height: 338px;
            margin-left: 18px;

            ${mediaQueries.md} {
                height: auto;
            }
        `,
);

const TEXT_SOFTWARE = `Consult an idea, add missing\n capabilities, expand your team, or\n hand over a project - we're\n experienced with all. And we do so\n from many years.`;
const TEXT_SOFTWARE_EXAMPLES = `Web Apps\n Mobile Apps\n Admin panels with advanced data analytics\n Payment Integration \n UX / UI design \n Outsourcing`;

const TEXT_DEEPTECH = `Leverage AI (machine learning),\n blockchain, drones, objects and\n sounds indentification or clean\n energy tech with us as we've\n developed and use these all.`;
const TEXT_DEEPTECH_EXAMPLES = `Machine learning\n Data sets & Neural networks training\n Image & sound AI processing\n Metaverse solutions\n Augmented & Virtual reality solutions`;

const TEXT_HARDWARE = `Electronics design and production.\n With our hardware solutions we\n bridge the gap between IoT\n concepts and functional products.`;
const TEXT_HARDWARE_EXAMPLES = `Electronics design\n Hardware prototyping\n Communication protocols\n Firmware & embedded software development\n Wireless connectivity`;

export const ExploreOurOfferSection = () => {
    const isMd = useBreakpoint('md');
    return (
        <Section title="explore our offer" variant="secondary">
            <Common.Div
                flex={isMd ? 'column' : 'row'}
                justifyContent="space-between"
                mt={8}
                mb={8}
                gap={isMd ? '32px' : '16px'}
            >
                <Common.Div>
                    <Common.Div
                        flex="row"
                        gap="16px"
                        alignItems="center"
                        mb={4}
                    >
                        <Common.Svg src={iconSoftware} />
                        <H3>software</H3>
                    </Common.Div>

                    <Common.Div flex="row" gap="16px" h="100%">
                        <VerticalLine />

                        <Common.Div flex="column">
                            <OfferText mt={2} mb={4}>
                                {drawTextWithLineBreaks(TEXT_SOFTWARE)}
                            </OfferText>
                            <OfferText>
                                {drawTextWithLineBreaks(TEXT_SOFTWARE_EXAMPLES)}
                            </OfferText>
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
                <Common.Div>
                    <Common.Div
                        flex="row"
                        gap="16px"
                        alignItems="center"
                        mb={4}
                    >
                        <Common.Svg src={iconDeepTech} />
                        <H3>deep tech</H3>
                    </Common.Div>

                    <Common.Div flex="row" gap="16px" h="100%">
                        <VerticalLine />
                        <Common.Div flex="column">
                            <OfferText mt={2} mb={4}>
                                {drawTextWithLineBreaks(TEXT_DEEPTECH)}
                            </OfferText>
                            <OfferText>
                                {drawTextWithLineBreaks(TEXT_DEEPTECH_EXAMPLES)}
                            </OfferText>
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
                <Common.Div>
                    <Common.Div
                        flex="row"
                        gap="16px"
                        alignItems="center"
                        mb={4}
                    >
                        <Common.Svg src={iconHardware} />
                        <H3>hardware</H3>
                    </Common.Div>
                    <Common.Div flex="row" gap="16px" h="100%">
                        <VerticalLine />
                        <Common.Div flex="column">
                            <OfferText mt={2} mb={4}>
                                {drawTextWithLineBreaks(TEXT_HARDWARE)}
                            </OfferText>
                            <OfferText>
                                {drawTextWithLineBreaks(TEXT_HARDWARE_EXAMPLES)}
                            </OfferText>
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
            </Common.Div>
            <ExploreButton onClick={() => navigate(ROUTE_OFFER)} />
        </Section>
    );
};
