import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import React, { useCallback, useState } from 'react';
import { TestimonialSlides } from 'sections/_universal/TestimonialSlides';
import {
    SwiperLeftArrowBack,
    SwiperRightArrowNext,
    SwiperWrapper,
} from 'sections/Home/TestimonialsSection.styled';
import Swiper from 'swiper';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const TestimonialsSection = () => {
    const isXs = useBreakpoint('xs');

    const [swiperRef, setSwiperRef] = useState<Swiper>();

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    // NOTE: Swiper breakpoints must be synchronized with breakpoints in styled
    return (
        <Section title="testimonials">
            <Common.Div
                flex="row"
                gap="32px"
                position="relative"
                mt={12}
                justifyContent="space-between"
            >
                {!isXs && <SwiperLeftArrowBack onClick={handlePrevious} />}
                <SwiperWrapper>
                    <TestimonialSlides setSwiperRef={setSwiperRef} />
                </SwiperWrapper>
                {!isXs && <SwiperRightArrowNext onClick={handleNext} />}
            </Common.Div>
        </Section>
    );
};
