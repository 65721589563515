import ArrowLeft from 'assets/images/_infoGraphics/arrowCarouselLeft.svg';
import ArrowRight from 'assets/images/_infoGraphics/arrowCarouselRight.svg';
import * as Common from 'components/_universal/Common';
import { swiperNavigationStyles } from 'components/_universal/commonStyles';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const StepGradientHead = styled(Common.Div)(
    ({ theme: { gradients, spacing } }) =>
        css`
            background: ${gradients.teal};
            display: flex;
            align-items: center;
            padding: ${spacing(3)};
            height: 78px;
        `,
);

export const TestimonialItemWrapper = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            flex-direction: column;
            height: 470px;
            background: ${colors.background};
            width: 315px;
        `,
);

export const SwiperDotsFiller = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            height: 48px;
            width: 100%;
            background: ${colors.section};
        `,
);

export const Description = styled(Common.Div)<{ italic?: boolean }>(
    ({ theme: { fontSizes }, italic }) =>
        css`
            ${italic && 'font-style: italic'};
            font-size: ${fontSizes.f14};
            line-height: 25px;
            font-weight: 400;
        `,
);

export const TestimonialContentWrapper = styled(Common.Div)(
    () =>
        css`
            display: flex;
            flex-direction: column;
            align-items: center;
        `,
);

export const ImageBackground = styled(Common.Svg)(
    () =>
        css`
            width: 100%;
        `,
);

export const SwiperLeftArrowBack = styled(Common.Div)(
    () =>
        css`
            width: 20px;
            background-image: url(${ArrowLeft});
            background-repeat: no-repeat;
            background-position: center;
        `,
);

export const SwiperRightArrowNext = styled(Common.Div)(
    () =>
        css`
            width: 20px;
            background-image: url(${ArrowRight});
            background-repeat: no-repeat;
            background-position: center;
        `,
);

// NOTE: Must be synchronized with breakpoints in swiper
export const SwiperWrapper = styled(Common.Div)(
    () =>
        css`
            @media (max-width: 1400px) {
                max-width: calc(315px * 2 + 2 * 16px);
            }

            @media (min-width: 1401px) {
                max-width: calc(315px * 3 + 2 * 16px);
            }

            @media (max-width: 1080px) {
                max-width: calc(315px * 1 + 2 * 16px);
            }

            ${mediaQueries.xs} {
                max-width: 100%;
            }

            .swiper-slide {
                justify-content: center;
                display: flex;
            }

            ${swiperNavigationStyles};
        `,
);
